.button:disabled {
  position: relative;
  opacity: 0.7;
  cursor: not-allowed;
}
.button:disabled:hover {
  transform: none;
}
.button.loading {
  padding-right: 4em;
  cursor: wait;
}
.button.loading:before {
  content: '';
  position: absolute;
  right: 1.6em;
  border-top: 3px solid white;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
